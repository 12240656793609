@import url(https://fonts.googleapis.com/css?family=Metal+Mania);
@import url(https://fonts.googleapis.com/css?family=Architects+Daughter);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #15273d, #415c7c);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: "Metal Mania";
  text-align: center;
  background: linear-gradient(to left, #304369, #051636);
  min-height: 100vh;
  color: rgb(212, 222, 230, 0.9);
  position: relative;
}

/* 20436d, 0b274b 3b79ac*/
/* #051636, #3b79ac */


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-fadeIn infinite 2.5s linear;
            animation: App-logo-fadeIn infinite 2.5s linear;
  }

  #Title {
    -webkit-animation: Title-fadeIn infinite 2.5s linear;
            animation: Title-fadeIn infinite 2.5s linear;
  }

  #ContactContainer {
    -webkit-animation: ContactContainer-fadeIn infinite 2s linear;
            animation: ContactContainer-fadeIn infinite 2s linear;
  }
}

/* .App-link {
  color: #61dafb;
} */

@-webkit-keyframes App-logo-fadeIn {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  /* 0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;} */

  0% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);}
  50% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.2);}
  100% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);}

}

@keyframes App-logo-fadeIn {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  /* 0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;} */

  0% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);}
  50% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.2);}
  100% {box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);}

}

@-webkit-keyframes Title-fadeIn {
  0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}
}

@keyframes Title-fadeIn {
  0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}
}

@-webkit-keyframes ContactContainer-fadeIn {
  0% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.7);}
  50% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.2);}
  100% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.7);}
}

@keyframes ContactContainer-fadeIn {
  0% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.7);}
  50% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.2);}
  100% {box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.7);}
}

@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}


.SocialMedia {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

#HomeParagraph {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

#HomeContainer {
  background-image: url(/static/media/HeaderJuanTwo.e6e15f8b.jpeg);
  /* background-color: rgba(0, 0, 0, .6);
  background-blend-mode: multiply; */
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center top;
  /* box-shadow: 0 5px 10px rgb(42, 41, 41); */
}

#Home {
  min-height: 50vh;
  width: 75vw;
  font-size: xx-large;
  border-radius: 0.5em;
  margin: auto;
  background-color: rgba(0, 0, 0, .5);
  padding: 10px 5vw 10px 5vw;
  position: relative;
}

#HomeParagraph {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#HomeParagraph span {
  background: linear-gradient(to right, #e9be31, #d48e0c);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

#HomeIntro {
  display: inline;
}

#HomeIntroMobile {
  display: none;
}

#AboutContainer {
  width: 100%;
  min-height: 100vh;
  /* box-shadow: 0 5px 10px rgb(42, 41, 41); */
  overflow-x: hidden;
  position: relative;
}

#AboutBackground {
  background-image: url(/static/media/layered-peaks.4ff5a17b.svg);
  background-size: cover;
  background-position: center top;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
}

#About {
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  width: 85%;
  margin: auto;
  min-height: 100vh;
}

#About .child {
  -webkit-transform: translateX(100%);
          transform: translateX(100%)
}

#About1 {
  min-height: 25vh;
  width: 65vw;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0 0.7em 0.7em 0;
  box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.5);
  padding: 4vh 2vw 2vh 4vw;
  margin: 5vh 0 5vh -32vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: center;
  text-justify:distribute-all-lines;
}

#About1 li {
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  display: list-item;
  font-size: x-large;
  text-align: left;
}

ul{ text-align:center;}

li{
  display: inline-block;
  padding:15px;
}

#About2 {
  min-height: 50vh;
  width: 75vw;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 0.7em 0 0 0.7em;
  margin-right: -23vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding-right: 3vw;
  /* padding-bottom: 1vh; */
  box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.TechnicalSkills {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent; */
  font-size: x-large;
}

.TechnicalSkills li {
  line-height: 8px;
  margin: 0;
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

/* .TechnicalSkills h3 {
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
} */

.Divider0 {
  width: 100vw;
  height: 70vh;
  /* padding-top: -40vh; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/layered-peaks2.851be312.svg);
  opacity: 0.3;
}

.Divider {
  width: 100vw;
  height: 50vh;
  margin-top: 5vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/layered1.482f6877.svg);
  opacity: 0.4;
}

.Divider2 {
  width: 100vw;
  height: 40vh;
  margin-top: -20vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(/static/media/layered2.db5d44b1.svg);
  opacity: 0.8;
}

#Projects {
  min-height: 100vh;
  margin-top: 3vh;;
  /* background: linear-gradient(to left, #051636, #3b79ac); */
  position: relative;
}

#ProjectsBackground {
  background-image: url(/static/media/polygon-scatter.6c5f6536.svg);
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

#Projecs h2 {
  z-index: 1001;
}

#Projects p {
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

#CarouselContainer {
  position: relative;
  border-radius: 0.91em;
  max-width: 50vw;
  height: 73vh;
  box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.6);
  margin: auto;
}

.CarouselLinks {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.SiteLinks p {
  position: absolute;
  bottom: 6px;
  right: 85px;
  width: 72px;
  z-index: 1000;
  /* color: white; */
  font-family: 'Metal Mania';
  font-size: 19px;
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  border-radius: 0.91em;
  box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);
  border: solid #C0C0C0;
  border-width: 0.5px 0 0 0.5px;
}

.SiteLinks p:hover {
  font-size: 20px;
  bottom: 8px;
}

.Github {
  box-shadow: 2px 2px 5px 2px rgb(112, 166, 190, 0.6);
}

.Github:hover {
  bottom: 3px;
}

/* #SiteLinks a {
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
} */

.slide-button {
  background: linear-gradient(rgb(112, 166, 190, 0.6), rgba(130, 130, 130, .5));
  margin: 2px 2px 2px 2px;
}

.slide-button-in {
  color: white;
  /* background: linear-gradient(#444444, #222222);
  background-color: white; */
}

#Publications {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(/static/media/Marina.f074e8e0.jpeg);
  background-attachment: fixed;
  width: 100%;
  background-size: cover;
  background-position: center top;
  /* box-shadow: 0 5px 10px rgb(42, 41, 41); */
}

#Contact {
  min-height: 100vh;
  position: relative;
}

#Contact h2 {
  z-index: 1000;
}

#ContactBackground {
  background-image: url(/static/media/stacked-waves.6235c6bb.svg);
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0.4;
}

#ContactContainer {
  min-height: 50vh;
  width: 30vw;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.7em;
  box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.5);
  margin: auto;
  margin-top: 3vh;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 4vw 2vh 4vw;
  position: relative;
}

#ProfilePicture {
  background-image: url(/static/media/Profile2.f103fa11.jpeg);
  height: 12rem;
  width: 12rem;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 5px 5px 15px 5px rgb(112, 166, 190, 0.5);
  /* margin-top: 5vh; */
  margin-bottom: 5vh;
}

#ContactText {
  font-size: 23px;
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  margin-top: 0.5vh;
  /* margin-bottom: 3vh; */
}

.ContactIcons {
  margin-top: 2vh;
  z-index: 900;
}

#Footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

#Footer p {
  font-size: 20px;
}

.navbar {
  position: sticky !important;
  top: 0;
  display: flex !important;
  justify-content: flex-end !important;
  z-index: 9998;
}

:root {
  --pcol: rgb(2, 36, 75) !important;
  --scol: rgb(45, 138, 214) !important;
  --tspeed: 0s !important;
  --fgrad: rgba(0, 0, 0, .8) !important;
  --sgrad: rgb(0, 0, 0, 0.6) !important;
  --rm-font-size: 0.975rem !important;
  --sm-font-size: 0.8rem !important;
}

#Title {
  font-size: x-large;
  position: fixed;
  top: 0;
  left: 10px;
  /* color: rgb(45, 138, 214) !important; */
  font-weight: 200;
  z-index: 9999;
  opacity: 1;
  display: flex;
}

.App-logo {
  display: none;
  position: fixed;
  top: 36px;
  left: 10px;
  background-image: url(/static/media/Logo.fb8ae0ee.png);
  width: 30px;
  min-height: 30px;
  background-size: cover;
  background-position: center top;
  z-index: 9999;
}

.FontGradient {
  background: linear-gradient(to right, #a0d0ec, #376ec0);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.SocialMedia {
  position: absolute;
  bottom: 10vh;
  left: 0;
  right: 0;
  color: rgb(212, 222, 230, 0.9);
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ComponentTitle {
  color: rgb(212, 222, 230, 0.9);
  font-size: xx-large;
  display: inline-block
}

.PublicationsCardsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  width: 90%;
}

.PublicationsCard {
  min-width: 26%;
  height: 65vh;
  background-size: cover;
  background-position: center top;
  border-radius: 0.7em;
  opacity: 0.9;
  position: relative;
  display: flex;
  justify-content: center;
}

#Publications h2 {
  background: linear-gradient(to right, #e9be31, #d48e0c);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

#Carousel {
  background-image: url(/static/media/Carousel.a6fcd31a.jpeg);
}

#Recursion {
  background-image: url(/static/media/Recursion.fe1faeeb.jpeg);
}

#Hooks {
  background-image: url(/static/media/Hooks.ec934da6.jpeg);
}

/* a.overlay {
  width: 100%;
  height:100%;
  position: absolute;
} */

.LinkIcon {
  position: absolute;
  top: 15px;
  right: 0;
}

#_2iAdU {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
}

/*  ----------------------------------------------- MOBILE DEVICES ----------------------------------------------*/

@media only screen and (min-width: 320px) and (max-device-width: 480px) {

  .navbar {
    justify-content: center !important;
  }

  .ComponentTitle {
    margin-top: 10vh;
  }

  #HomeContainer {
    background-image: url(/static/media/Marina.f074e8e0.jpeg);
  }

  #Home {
    font-size: 3.5vh;
    min-height: 60vh;
  }

  #HomeIntroMobile {
    display: inline;
    background: linear-gradient(to right, #e9be31, #d48e0c);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  #HomeIntro {
    display: none;
  }

  #Projects {
    margin-bottom: 5vh;;
  }

  #Publications {
    background-image: url(/static/media/GG-Background.778baf8b.jpeg);
  }

  .PublicationsCardsContainer {
    flex-direction: column;
  }

  .PublicationsCard {
    min-width: 86%;
    margin-bottom: 20px;
  }

  #About1 {
    width: 85vw;
    margin-left: -15vw;
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  }

  #About1 li {
    font-size: large;
  }

  #About2 {
    width: 85vw;
    margin-right: -15vw;
  }

  .TechnicalSkills {
    font-size: large;
  }

  .TechnicalSkills li {
    line-height: 16px;
    margin: 0;
  }

  .Divider0 {
    height: 80vh;
    background-image: url(/static/media/stacked-peaks2.fba2c2c0.svg);
  }

  #CarouselContainer {
    max-width: 90vw;
    margin-top: -3.5vh;
  }

  .SiteLinks p {
    left: 8px;
    bottom: -8px;
  }

  .Github {
    box-shadow: none;
    bottom: -14px;
    right: -14px;
  }

  #ContactBackground {
    background-image: url(/static/media/stacked-waves2.b9360467.svg);
  }

  #ContactContainer {
    min-height: 30vh;
    width: 80vw;
    margin-top: -1vh;
  }

  #ProfilePicture {
    height: 8rem;
    width: 8rem;
    margin-bottom: 4vh;
  }

  #ContactText {
    font-size: 20px;
  }

  #Title {
    display: none;
  }

  .App-logo {
    display: flex;
  }
}

/*  ----------------------------------------------- IPADS  ----------------------------------------------*/

@media only screen and (min-width: 768px) and (max-device-width: 1024px) {

  #Home {
    min-height: 45vh;
    /* font-size: x-large; */
  }

  #HomeIntroMobile {
    display: inline;
    background: linear-gradient(to right, #e9be31, #d48e0c);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  #HomeIntro {
    display: none;
  }

  #About2 {
    /* width: 85vw;
    margin-right: -15vw; */
    min-height: 250px;
  }

  #CarouselContainer {
    /* max-width: 60vw; */
    /* height: 50vh; */
    font-size: xx-large;

  }

  .PublicationsCardsContainer {
    flex-direction: column;
  }

  .PublicationsCard {
    min-width: 86%;
    margin-bottom: 20px;
  }

  #ContactContainer {
    min-height: 250px;
    /* width: 50vw; */
  }

  #Title {
    display: none;
  }

  .App-logo {
    display: flex;
  }
}

/*  ----------------------------------------------- SURFACE DUO  ----------------------------------------------*/

@media only screen and (min-width: 540px) and (max-device-width: 767px) {

  #Home {
    font-size: x-large;
  }

  #HomeIntroMobile {
    display: inline;
    background: linear-gradient(to right, #e9be31, #d48e0c);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
  }

  #HomeIntro {
    display: none;
  }

  #CarouselContainer {
    max-width: 60vw;
    min-height: 73vh;

  }

  .PublicationsCardsContainer {
    flex-direction: column;
  }

  .PublicationsCard {
    min-width: 86%;
    margin-bottom: 20px;
  }

  #ContactContainer {
    /* min-height: 50vh; */
    width: 50vw;
  }

  #Title {
    display: none;
  }

  .App-logo {
    display: flex;
  }

}

